import React from "react"
import ProfileRouteAnonymous from "../../../../../components/auth/ProfileRoute/Anonymous"
import ProposalBuy from "../../../../../components/Proposal/ProposalBuy"

const ProposalsBuyAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <ProposalBuy url={props.location.href} />
  </ProfileRouteAnonymous>
)

export default ProposalsBuyAnonymousPage
